import { render, staticRenderFns } from "./ViewTechnicalFolders.vue?vue&type=template&id=8b4ef1b8&"
import script from "./ViewTechnicalFolders.vue?vue&type=script&lang=js&"
export * from "./ViewTechnicalFolders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtnGroup,QSeparator,QBtn});
