<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck
        :title="getRes('Form.Field.TechnicalFolder')"
        :cards="items"
        :statuses="statuses"
        v-slot="{ card, status }"
        filter-by-status
      >
        <pro-deck-card
          v-bind="card"
          :status="status ? status.label : ''"
          :statusColor="status ? status.color : ''"
        >
          <template v-slot:action>
            <q-btn-group
              outline
              style="min-width: max-content"
              v-if="status.key !== 'Failed'"
            >
              <q-separator v-if="card.buttons.length" />
              <q-btn
                no-caps
                icon="PROSmart-Docs"
                :label="action.label"
                v-for="(action, index) in card.buttons"
                :key="index"
                :to="action.to"
              />
              <q-btn
                no-caps
                v-if="card.processId"
                icon="PROSmart-Docs"
                :label="getRes('System.Button.View')"
                @click.stop="showRecord(card.processId)"
              />
            </q-btn-group>
          </template>
        </pro-deck-card>
      </pro-deck>
    </div>
    <audit-trail-record-dialog ref="audit"></audit-trail-record-dialog>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import AuditTrailRecordDialog from "@/components/PROSmart/AuditTrailRecordDialog";

export default {
  components: { AuditTrailRecordDialog, ProMenuLayout, ProDeckCard, ProDeck },
  data() {
    return {
      items: [],

      statuses: [
        {
          key: "New",
          label: this.getRes("Form.Section.SubOffer"),
          color: "green",
        },
        {
          key: "NoOffer",
          label: this.getRes("Form.Section.SubNoOff"),
          color: "orange",
        },
        {
          key: "NoResponse",
          label: this.getRes("System.OfferType.NoResponse"),
          color: "red",
        },
        // {
        //   key: "LateApproved",
        //   label: this.getRes("System.OfferType.LateApproved"),
        //   color: "teal",
        // },
        // {
        //   key: "LateReject",
        //   label: this.getRes("System.OfferType.LateReject"),
        //   color: "brown",
        // },
        {
          key: "Failed",
          label: this.getRes("System.OfferType.Failed"),
          color: "pink",
        },
        {
          key: "Invalid",
          label: this.getRes("System.OfferType.Invalid"),
          color: "purple",
        },
      ],
    };
  },

  methods: {
    async getTenderers() {
      return (
        await this.$proSmart.tender.getTendererAndAssessmentVersion(
          this,
          this.$route.params.id
        )
      ).tenderer
        .sort((left, right) => left.ref.localeCompare(right.ref))
        .reduce(
          (
            items,
            {
              id,
              hcmUserId,
              ref,
              name,
              responseType,
              tendererVersion,
              assessmentVersion,
              processId,
            }
          ) => {
            const actions = [];

            tendererVersion &&
              actions.push({
                label: this.getRes("Form.Section.TenderVersion"),
                to: {
                  name: "ViewTendererVersion",
                  params: {
                    userId: hcmUserId,
                    stepperId: this.$route.params.stepperId,
                  },
                },
              });
            assessmentVersion &&
              actions.push({
                label: this.getRes("Form.Section.AssessmentVersion"),
                to: {
                  name: "ViewAssessmentVersion",
                  params: {
                    userId: hcmUserId,
                    stepperId: this.$route.params.stepperId,
                  },
                },
              });

            if (responseType === "NoResponse") {
              processId = null;
            }

            if (responseType === "Withdraw") {
              responseType = "NoResponse";
            }

            if (responseType === "Supersede") {
              responseType = "New";
            }

            return [
              ...items,
              {
                alias: ref,
                title: name,
                companyName: name,
                buttons: actions,
                status: responseType,
                id,
                processId,
              },
            ];
          },
          []
        );
    },
    showRecord(processId) {
      this.$refs.audit.showInfoViewForm(processId, this.$route.params.id, true);
    },
  },

  async created() {
    this.items = await this.getTenderers();
  },
};
</script>
